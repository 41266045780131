// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

console.log("Loading Even Session Report Controller")

import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    console.log("Hello, Event Session Reports!")
    document.querySelectorAll(".participant-li").forEach((element) => {
      let id = element.dataset.id
      let event = element.dataset.event
      let url = `/users_admin/${id}/ratings_for_event/${event}`
      //fetch the url
      fetch(url)
        .then(response => response.json())
        .then(data => {
          console.log(data)
          element.querySelector(".sim-ratings-count").innerHTML = `Simulations (lead): <strong>${data.scenario_ratings_lead.length}</strong>`
          element.querySelector(".skill-ratings-count").innerHTML = `Skill Ratings: <strong>${data.skill_ratings.length}</strong>`
          element.querySelector(".field-ratings-count").innerHTML = `Field Ratings (lead): <strong>${data.field_ratings_lead.length}</strong>`
        })
    })
  }
}